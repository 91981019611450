import React, { useEffect, memo } from "react";

import SEO from "../components/layout/seo";
import SubHeader from "../components/layout/subheader";

import Space from "../components/space";

import Mapa from "../components/fale-conosco/mapa";
import Formulario from '../components/fale-conosco/formulario';

import Aos from 'aos';

import { useStaticQuery, graphql } from 'gatsby';




const FaleConoscoIcon = ({name, children}) =>
{
    const data = useStaticQuery(graphql`
        query {
            iconendereco: file(relativePath: { eq: "icon-endereco.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icontelefone: file(relativePath: { eq: "icon-telefone.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            iconemail: file(relativePath: { eq: "icon-email.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    if ( name === 'icon-endereco.png' ) {
        return <img draggable="false" src={data.iconendereco.childImageSharp.fluid.src} alt="" width="33" height="47" />
    } else if ( name === 'icon-telefone.png' ) {
        return <img draggable="false" src={data.icontelefone.childImageSharp.fluid.src} alt="" width="47" height="47" />
    } else if ( name === 'icon-email.png' ) {
        return <img draggable="false" src={data.iconemail.childImageSharp.fluid.src} alt="" width="42" height="48" />
    }
}





















const onFocus = () =>
{
    //console.log('PaginaFaleConosco:','onFocus');
    Aos.refresh();
}

const TempPaginaFaleConosco = () =>
{
    useEffect(() =>
    {
        //
        // componentDidMount

        //console.log('PaginaFaleConosco:','render');

        if ( typeof Aos !== `undefined` && typeof window !== `undefined` ) {
            setTimeout(function() {
                //Aos.refresh();
                Aos.init();
            }, 500);
            window.addEventListener("focus", onFocus);
        }

        //
        // componentWilUnmount

        return () => {
            //console.log('PaginaFaleConosco:','unmount');

            if ( typeof Aos !== `undefined` && typeof window !== `undefined` )
            {
                window.removeEventListener("focus", onFocus);
                //Aos.disable();
            }
        };
    }, []);

    return (
        <>
            <SEO title="Fale conosco" />
            <SubHeader pagename="Fale conosco" bg="page-bg-fale-conosco.jpg" />

            <div className="container">
                <Space altura={50} />
                <div className="contacts">
                    <div className="contact__item__wrapper contact__item__address">
                        <div className="contact__item">
                            <div className="contact__icon contact--size1"><FaleConoscoIcon name="icon-endereco.png" /></div>
                            <div className="contact__info">
                                <h3>ENDEREÇO</h3>
                                <p>Francisco Braga, 335 – Sala 03<br />Vila Bandeirantes - Araçatuba/SP</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact__item__wrapper contact__item__telefone">
                        <div className="contact__item">
                            <div className="contact__icon contact--size2"><FaleConoscoIcon name="icon-telefone.png" /></div>
                            <div className="contact__info">
                                <h3>TELEFONE</h3>
                                <p>(18) 3216-2987 - Telefone</p>
                                <p>(18) 99121-0260 - WhatsApp</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact__item__wrapper contact__item__email">
                        <div className="contact__item">
                            <div className="contact__icon contact--size3"><FaleConoscoIcon name="icon-email.png" /></div>
                            <div className="contact__info">
                                <h3>E-MAIL</h3>
                                <p>comercial@arinteligenciatributaria.com.br</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Space altura={40} id="formulario" />
                <div className="bloco__contato">
                    <Formulario />
                    <Mapa />
                </div>
                <Space altura={50} />
            </div>
        </>
    )
}

const PaginaFaleConosco = memo(TempPaginaFaleConosco);

export default PaginaFaleConosco;